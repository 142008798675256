/**
 * Initializes the video element to display a live feed from the user's camera.
 * If a preview is already present, the function skips this.
 *
 * @param {number} size - The desired width of the video feed.
 * @param {Element} video - The video element that will display the camera feed.
 * @param {boolean} previewPresent - Flag indicating if a preview is currently displayed.
 */
export default (size, video, previewPresent) => {
  video.setAttribute("width", size);
  if (!previewPresent) {
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: false }) // Request access to the user's camera
      .then((stream) => {
        video.srcObject = stream;
        video.play();
      })
      .catch((error) => {
        console.error(error);
      });
  }
};
