import getUserMediaInput from "./getUserMediaInput";
import stopUserMediaInput from "./stopUserMediaInput";
import takePhoto from "./takePhoto";

const MEDIA_WIDTH = 800;

/**
 * Initializes the user media (camera) for a survey question.
 * It chooses between mobile or desktop setup based on the screen width.
 *
 * @param {Object} survey - The current survey object.
 * @param {Object} options - Additional options, including the current question.
 */
export default (survey, options) => {
  const questionElement = document.querySelector(`[data-name="${options.question.name}"]`);
  const button = questionElement.querySelector(".sd-file__choose-btn");
  const videoLocation = questionElement.querySelector(".sd-file__wrapper");

  questionElement.querySelector(".sd-file__drag-area").classList.add("take-photo-question");

  if (isMobileView()) {
    handleMobileSetup(button, options);
  } else {
    handleDesktopSetup(survey, button, options, videoLocation, questionElement);
  }
};

/**
 * Sets up the mobile view, changing the input type to capture images directly.
 */
function handleMobileSetup(button, options) {
  let input = options.htmlElement.querySelector("input");

  input.type = "file";
  input.setAttribute("capture", "");
  input.accept = "image/*";

  button.innerText = "Take photo";
}

/**
 * Sets up the desktop view, displaying either the video feed or a previously captured image.
 */
function handleDesktopSetup(survey, button, options, videoLocation, questionElement) {
  const video = document.createElement("video");
  const hasSurveyValue = Boolean(survey.getValue(options.question.name));
  let state = { imageLoadedFromSurveyJs: false };

  video.classList.add("video-input-field");
  button.innerText = hasSurveyValue ? "Retake" : "Take photo";

  videoLocation.insertBefore(video, videoLocation.firstChild);
  getUserMediaInput(MEDIA_WIDTH, video, hasSurveyValue);

  if (hasSurveyValue) {
    videoLocation.insertBefore(getImagePreview(questionElement), videoLocation.firstChild);
    video.style.display = "none";
    state = { imageLoadedFromSurveyJs: true };
  }

  button.addEventListener("click", (event) => {
    handleButtonClick(event, state, questionElement, video, button, options, videoLocation);
  });
}

/**
 * Handles the logic for button clicks and toggling between showing the video feed and displaying a captured image.
 */
function handleButtonClick(event, state, questionElement, video, button, options, videoLocation) {
  event.preventDefault();

  if (state.imageLoadedFromSurveyJs) {
    state.imageLoadedFromSurveyJs = false;

    getImagePreview(questionElement).style.display = "none";
    video.style.display = "block";
    getUserMediaInput(MEDIA_WIDTH, video, false);

    toggleButton(true, button);
  } else if (!questionElement.querySelector(".image-output-field")) {
    const photoData = takePhoto(video, document.createElement("canvas"));

    /* global survey */
    setSurveyValue(survey, options.question.name, photoData);
    setSurveyValue(survey, options.question.name.replace("review_stage_", ""), photoData);

    const image = document.createElement("img");

    getImagePreview(questionElement).style.display = "none";
    video.style.display = "none";

    image.src = photoData;
    image.classList.add("image-output-field");
    videoLocation.insertBefore(image, videoLocation.firstChild);

    stopUserMediaInput(video);
    toggleButton(false, button);
  } else {
    video.style.display = "block";
    questionElement.querySelector(".image-output-field").remove();

    getUserMediaInput(MEDIA_WIDTH, video, false);
    toggleButton(true, button);
  }
}

/**
 * Retrieves the image preview element from the current question.
 */
function getImagePreview(questionElement) {
  return questionElement.querySelector(".sd-file__list");
}

/**
 * Sets an image value for a given survey question.
 */
function setSurveyValue(survey, name, data) {
  survey.setValue(name, [{ name: `${name}.png`, type: "image/png", content: data }]);
}

/**
 * Toggles the appearance and text of the button between "Take photo" and "Retake".
 */
function toggleButton(isTakePhoto, button) {
  if (isTakePhoto) {
    button.classList.remove("sd-file__choose-btn--icon");
    button.classList.add("sd-file__choose-btn--text");
    button.innerText = "Take photo";
  } else {
    button.classList.remove("sd-file__choose-btn--text");
    button.classList.add("sd-file__choose-btn--icon");
    button.innerText = "Retake";
  }
}

/**
 * Determines if the current view should be mobile based on screen width.
 */
function isMobileView() {
  return window.matchMedia("only screen and (max-width: 768px)").matches;
}
