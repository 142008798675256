import saveSurveyData from "@app/controllers/bare_forms/form_functions/saveSurveyData";

export default (survey, generateDocuments = false) => {
  var state = document.getElementById("surveyContainer").getAttribute("data-response-state");

  if (state == "WA") {
    survey = updateWASurvey(survey);
  }
  saveSurveyData(survey, false, generateDocuments);
};

function updateWASurvey(survey) {
  var applicant_relationship_to_deceased_legal =
    survey.data.applicant_relationship_to_deceased_legal;

  if (
    applicant_relationship_to_deceased_legal &&
    applicant_relationship_to_deceased_legal == "nearest_surviving_relative"
  ) {
    if (survey.data.applicant_relationship_to_deceased == "other") {
      survey.data.nearest_surviving_relative[0] = {
        name_full: survey.data.applicant_name.first + ' ' + survey.data.applicant_name.last,
        relationship_to_deceased: survey.data.applicant_relationship_to_deceased_other_details,
      };
    } else {
      survey.data.nearest_surviving_relative[0] = {
        name_full: survey.data.applicant_name.first + ' ' + survey.data.applicant_name.last,
        relationship_to_deceased: survey.data.applicant_relationship_to_deceased,
      };
    }
  }

  return survey;
}
