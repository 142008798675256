import sendSentryError from "./sendSentryError";

export default async (survey, expectedUpload) => {
  var data = survey.data;
  data.pageNo = survey.currentPageNo;

  let url = document.getElementById("surveyContainer").getAttribute("data-response-uploads-url");
  let elem = document.querySelector(".button--primary");

  elem.innerHTML = '<div class="spinner-loader"><div class="spinner"></div></div>';

  try {
    let poll = setInterval(async () => {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          "X-CSRF-Token": document.querySelector("meta[name=csrf-token]")?.content,
        },
      });
      if (!response.ok) {
        clearInterval(poll);
        throw getError(response);
      }
      let responseData = await response.json();
      let uploads = responseData.uploads;
      if (uploads.length > 0) {
        let uploadMatch = uploads.find((upload) => upload === expectedUpload);
        if (uploadMatch) {
          clearInterval(poll);
          survey.nextPage();
        } else {
          // Double, but we want to leave the old way for now.
          sendSentryError(`Upload ${expectedUpload} not found in response`);
          throw getError(`Upload ${expectedUpload} not found in response`);
        }
      }
    }, 3000);
  } catch (error) {
    throw getError(error);
  }

  const getError = (error) => {
    let newError = new Error(error);
    newError.name = `NokPaperwork: ${error}`;

    return newError;
  };
};
