const COUNTRY_OF_BIRTH_SELECTOR = '[aria-label="What country were they born in?"]';

/**
 * Initializes specific behaviors for dropdown inputs.
 * - For dropdowns not related to the "country of birth" question, the input is made read-only.
 * - The caret color is changed on focus and blur to improve visibility.
 */
export default (_) => {
  const dropDownInputs = document.querySelectorAll(".sd-dropdown__filter-string-input");
  dropDownInputs.forEach((input) => {
    if (!input.closest(COUNTRY_OF_BIRTH_SELECTOR)) {
      input.readOnly = true;
    }

    input.addEventListener("focus", function () {
      this.style.caretColor = "transparent";
    });

    input.addEventListener("blur", function () {
      this.style.caretColor = "auto";
    });
  });
};
